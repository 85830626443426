.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  margin-top: 150px;
}

html{
  width: 100%;
  height: 100%;
}

.header {
background-color: white;
height: 55px; 
width: 100%; 
}

.header a {
float: left;
display: block;
color: #191A31;
text-decoration: none;
margin-left: 15px;
margin-top: 15px;
font-size: 20px;
}

.header a:hover {
color: 428bca;
}

.img-circle {
  text-align: center;
  display: block;
  border: 3px solid #fff;
  border-radius: 500px;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
}

.img-circle:hover{
  border-color: #772ce8; 
  transition: all 300ms ease-out;
}

img.profile-picture {
  width:200px; 
  height:200px;
  border-radius: 50%;
  position: relative;
  transition: all 300ms ease-out;
  float: left;
}

.content{
  margin-top: 100px;
}

.text{
  text-align: center;
  font-family: Roobert,Inter,Helvetica Neue,Helvetica,Arial,sans-seri,
  monospace;
}

.name{
  transition: all 300ms ease-out;
  position: relative;
}

.name:hover{
  color: #772ce8; 
  transition: all 300ms ease-out;
}

.socials{
  text-align: center;
  position: relative;
}

.social-icon{
  font-size: 35px;
  color: #9147ff;
  margin: 15px;
  transition: all 300ms ease-out;
}

.social-icon:hover{
  color: #772ce8;
  transition: all 300ms ease-out;
}

footer{
  position: relative;
  margin-top: 400px;
}

.n81{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition: all 300ms ease-out;
}

p.text{
  font-size: 25px;
  color: #ffffff;
  transition: all 300ms ease-out;
}

p.text:hover{
  color: #9147ff;
}

.info {
  padding-left: 20px;
}

.presentation{
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.secondary-purple{
  color:#772ce8;
}

@media only screen and (max-width: 500px) {
  .info {
      padding-left: 0;
  }
}

@media only screen and (max-width: 580px) {

  .name {
      font-size: 40px;
  }

  p.text{
      font-size: 20px;
  }

  .social-icon{
      font-size: 30px;
  }

  img.profile-picture {
      width:175px; 
      height:175px;
  }

  footer{
      margin-top: 200px;
  }
}

@media only screen and (max-width: 380px) {

  .name {
      font-size: 30px;
  }

  p.text{
      font-size: 15px;
  }

  .social-icon{
      font-size: 25px;
  }

  img.profile-picture {
      width:150px; 
      height:150px;
  }

  footer{
      margin-top: 100px;
  }

  .content{
      margin-top: 50px;
  }
}